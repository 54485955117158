import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

// eslint-disable-next-line import/no-extraneous-dependencies

import BlokWrap from "@/components/atoms/BlokWrap";
import styles from "./index.module.css";

const UI = ({ blok }: { blok: any }) => {
  const { klavioId, id } = blok;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-blok
      anchor={id}
      data-layout="page"
    >
      <div className={clsx(styles.container, styles[blok?.alignment])}>
        <div className={`klaviyo-form-${klavioId}`}></div>
      </div>
    </BlokWrap>
  );
};

export default UI;
