"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setCartItemQuantity } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import optimisticQuantityUpdate from "../utils/optimisticUpdate";

export interface IUpdateQuantityVariables {
  line: string;
  newQuantity: number;
}

export default function useUpdateQuantity() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);

  const updateQuantity = useMutation({
    mutationFn: (variables: IUpdateQuantityVariables) =>
      setCartItemQuantity(variables, customerToken),

    onMutate: async (variables) => {
      if (window.CentraCheckout) window.CentraCheckout.suspend();
      await queryClient.cancelQueries({ queryKey: ["selection"] });

      // Snapshot the previous value
      const oldData = queryClient.getQueryData(["selection"]);

      //  Optimistically update to the new value
      queryClient.setQueryData(["selection"], (oldCart: any) => {
        return optimisticQuantityUpdate(oldCart, {
          line: variables.line,
          newQuantity: variables.newQuantity
        });
      });

      // Return a context object with the snapshotted value
      return { oldData };
    },

    onError: (error, _variables, context) => {
      if (context?.oldData) {
        queryClient.setQueryData(["selection"], context?.oldData);
      }
    },

    onSettled: () => {
      if (window.CentraCheckout) window.CentraCheckout.suspend();
      queryClient.invalidateQueries({ queryKey: ["selection"] });
    }
  });
  return { updateQuantity };
}

export type UpdateQuantity = ReturnType<
  typeof useUpdateQuantity
>["updateQuantity"];
