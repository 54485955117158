"use client";

import clsx from "clsx";
import { useEffect, useState } from "react";

import env from "@/env.mjs";

import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import styles from "./newsletter-input.module.css";

type State = "idle" | "fetching" | "success" | "error";

const NewsletterInput = ({ className }: { className?: string }) => {
  const t = useTranslations();
  const [value, setValue] = useState("");
  const [state, setState] = useState<State>("idle");
  const path = usePathname();

  useEffect(() => {
    setValue("");
    setState("idle");
  }, [path]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const base = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

    setState("fetching");
    const res = await fetch(`${base}/newsletter-subscription/${value}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_field: value,
      }),
    });
    if (res.ok) {
      setState("success");
      return;
    } else {
      setState("error");
      return;
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(styles.form, className)}
      data-state={state}
    >
      <label
        className={clsx(styles.label, value.length > 0 && styles.active)}
        aria-hidden={state === "success"}
        data-visible={state !== "success"}
      >
        <input
          type={"email"}
          className={clsx(styles.input, value.length > 0 && styles.active)}
          aria-hidden={state === "success"}
          data-visible={state !== "success"}
          value={value}
          placeholder="Email"
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={styles.button__wrapper}>
          <button className={styles.button} type="submit">
            {"→"}
          </button>
        </div>
      </label>
      <p
        className={styles.success}
        aria-hidden={state !== "success"}
        data-visible={state === "success"}
      >
        {t("footer.newsletter.successText")}
      </p>
      <p
        className={styles.error}
        aria-hidden={state !== "error"}
        data-visible={state === "error"}
      >
        {t("footer.newsletter.error")}
      </p>
    </form>
  );
};

export default NewsletterInput;
