"use client";

import env from "@/env.mjs";
import { ICartItem } from "@/lib/centra/formatters/types";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISelectionItem } from "../types";
import { optimisticAddToCart } from "../utils/optimisticUpdate";

const postEngraving = async (
  variables: {
    items: {
      id: string;
      quantity: number;
      comment?: { item: string; txt: string; relatedItem: string };
    }[];
  },
  token: string,
) => {
  const response = await fetch(`/api/engraving`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Token": token,
    },
    body: JSON.stringify(variables),
  });

  if (!response.ok) {
    throw new Error("Error adding to cart");
  }

  const data = await response.json();

  return data;
};

function useAddEngravingMutation() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);

  const addEngravingMutation = useMutation({
    mutationFn: (variables: {
      items: {
        id: string;
        quantity: number;
        comment?: { item: string; txt: string; relatedItem: string };
      }[];
    }) => postEngraving(variables, customerToken),

    onMutate: async ({ items }) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });

      const oldData = queryClient.getQueryData(["selection"]);
      const newEngraving = items[0];

      queryClient.setQueryData(["selection"], (oldCart: any) =>
        optimisticAddToCart(oldCart, {
          itemId: newEngraving.id,
          cartItem: {
            comment: JSON.stringify({ comment: newEngraving.comment }),
            item: newEngraving.id,
            quantity: 1,
            // TODO: Add engraving prices to calculate optimistic summary update
          } as Partial<ISelectionItem>,
        }),
      );

      return { oldData };
    },

    onSuccess: ({ value }) => {
      value && queryClient.setQueryData(["selection"], value);
    },

    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },
  });

  return addEngravingMutation;
}

interface IAddEngravingProps {
  txt: string;
  onSuccess?: () => void;
  itemData: ICartItem;
}

export const useAddEngraving = () => {
  const ENGRAVING_ID = env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID;

  const addEngravingMutation = useAddEngravingMutation();

  const addEngraving = ({ txt, itemData, onSuccess }: IAddEngravingProps) => {
    const relatedItem = itemData?.itemId;
    const item = itemData?.uri.toUpperCase() + "-" + itemData?.size;

    addEngravingMutation.mutate(
      {
        items: [
          {
            id: ENGRAVING_ID,
            quantity: 1,
            comment: { item, txt, relatedItem },
          },
        ],
      },
      { onSuccess },
    );
  };

  return {
    addEngraving,
    isLoading: addEngravingMutation.isPending,
  };
};
