// @ts-nocheck
// Don't understand the TS data.description issue
import { serverFetchPCById } from "@/app/serverFunctionsCentra";
import env from "@/env.mjs";
import usePriceList from "@/lib/hooks/useSelection/cart/usePriceList";
import { useQuery } from "@tanstack/react-query";

// Need only "453" from "453-2932"
const ENGRAVING_ID = env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID.split("-")[0];

export const useEngravingQuery = () => {
  return useQuery({
    queryKey: ["engraving"],
    queryFn: () => serverFetchPCById(ENGRAVING_ID),
    refetchOnMount: false
  });
};
export const useEngravingPrice = () => {
  const { data } = useEngravingQuery();

  const engravingPrices = data?.description?.price;
  const { price: engravingPrice } = usePriceList(engravingPrices);

  return engravingPrice;
};
