import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

// eslint-disable-next-line import/no-extraneous-dependencies
import { render } from "storyblok-rich-text-react-renderer";

import type { BodyStoryblok } from "@/types/storyblok-blok-types";

import BlokWrap from "@/components/atoms/BlokWrap";
import clsx from "clsx";
import StoryblokComponent from "../../StoryblokComponent/StoryblokComponent";
import styles from "./body.module.css";

const UI = ({ blok }: { blok: BodyStoryblok }) => {
  const { body, id } = blok;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={styles.container}
      data-blok
      animated="custom"
      anchor={id}
    >
      <div
        className={clsx(
          styles.container__content,
          blok?.alignment && styles[blok.alignment],
        )}
        data-layout="page"
      >
        <div className={styles.content__textwrap}>
          <div className={styles.content__text}>
            {render(body, {
              defaultBlokResolver: (name, props) => (
                <StoryblokComponent blok={{ component: name, ...props }} />
              ),
            })}
          </div>
        </div>
      </div>
    </BlokWrap>
  );
};

export default UI;
