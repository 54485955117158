import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import BlokWrap from "@/components/atoms/BlokWrap";
import StoryblokLink from "@/lib/bloks/atoms/StoryblokLink";
import { getAllStories, resolveStories } from "@/lib/storyblok/storyblokSetup";
import ArticleSlides from "./components/ArticleSlides";
import styles from "./content.module.css";

const ContentSection = async ({ blok }: any) => {
  const { title, latest, latestFolder = "stories", link, id } = blok;
  let { articles } = blok;
  let resolvedArticles;
  if (latest) {
    articles = await getAllStories(latestFolder);
    if (articles) {
      resolvedArticles = articles.filter(
        (article: any) => !article.is_startpage,
      );
    }
  } else {
    const resolved = await resolveStories(articles);
    resolvedArticles = Object.values(resolved);
  }

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-blok-background
      data-layout={"full"}
      anchor={id}
      className={`content-container ${styles.content}`}
    >
      {title && (
        <div className={styles.content__title}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={styles.sliderWrap}>
        <ArticleSlides articles={resolvedArticles} />
      </div>

      {link?.[0] && (
        <StoryblokLink
          blok={{ link: link?.[0].link, text: link?.[0].text }}
          color={"transparent"}
        />
      )}
    </BlokWrap>
  );
};

export default ContentSection;
