// @ts-nocheck
import useDevice from "@/lib/hooks/useDevice";
import ReactCountryFlag from "react-country-flag";
import Select, { components } from "react-select";
import style from "./dropdown.module.css";
const UI = (props) => {
  const { options, onChange, styles, value, markets } = props;

  const { mobile } = useDevice();

  if (markets && !mobile) {
    const CustomOption = ({ innerProps, isDisabled, children, value }) => {
      if (isDisabled) {
        return null;
      }
      return (
        <div {...innerProps} className={style.option}>
          <ReactCountryFlag
            className={style.svg}
            countryCode={value}
            svg
            alt={location?.location}
          />
          {children}
        </div>
      );
    };

    const CustomControl = ({ children, ...props }) => {
      return (
        <components.Control {...props}>
          {props.hasValue && (
            <ReactCountryFlag
              className={style.svg}
              alt={location?.location}
              countryCode={props.getValue()?.[0]?.value}
              svg
            />
          )}{" "}
          {children}
        </components.Control>
      );
    };

    return (
      <Select
        classNames={{
          container: () => style["container"],
        }}
        components={{ Option: CustomOption, Control: CustomControl }}
        options={options}
        onChange={onChange}
        styles={styles}
        value={value}
      />
    );
  }

  return (
    <Select
      classNames={{
        container: () => style["container"],
      }}
      options={options}
      onChange={onChange}
      styles={styles}
      value={value}
    />
  );
};

export default UI;
