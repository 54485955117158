"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateUser } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import type { IUpdateUser } from "../types";

export default function useUpdateUser() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const update = useMutation({
    mutationFn: (variables: IUpdateUser) => updateUser(variables, customerToken),

    onError: async (error: Error) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();
      console.error("error", error);
      return error;
    },

    onSuccess: async (data) => {
      queryClient.setQueryData(["selection"], data);
      queryClient.invalidateQueries({
        queryKey: ["selection"]
      });
    }
  });

  return { update };
}
