"use client";

import { type HTMLAttributes } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./blokwrap.module.css";

export type AnimationType = "fadeY" | "custom" | "";
type DivProps = HTMLAttributes<HTMLDivElement> & {
  animated?: AnimationType;
  anchor?: string;
};

const BlokWrap = (props: DivProps) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { animated, anchor, children, ...div } = props;
  return (
    <div
      ref={ref}
      data-blok
      data-animated={animated}
      data-view={inView}
      {...div}
    >
      {anchor && <div id={anchor} className={styles.anchor} />}
      {children}
    </div>
  );
};

export default BlokWrap;
