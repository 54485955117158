"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";

import { PATHS } from "@/consts";
import { loginUser } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

const getRedirectParam = () => {
  const url = new URL(window.location.href);
  const redirect = url.searchParams.get("redirect");
  return redirect;
};

export default function useLogin() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const router = useRouter();

  const login = useMutation({
    mutationFn: (variables: { email: string; password: string }) =>
      loginUser(variables, customerToken),

    onError: async (error: Error) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();

      return error;
    },

    onSuccess: async (data) => {
      Cookies.set("token", data.token, {
        expires: 365,
        secure: true,
        sameSite: "strict"
      });

      const redirect = getRedirectParam();

      queryClient.setQueryData(["selection"], data);
      queryClient.invalidateQueries({
        queryKey: ["selection"]
      });
      router.push(redirect || PATHS.account);
      router.refresh();
    }
  });

  return { login };
}
