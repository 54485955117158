import { create, type StateCreator } from "zustand";
import { persist } from "zustand/middleware";

import type { IEnvironmentState } from "./persistedSlices/environment";
import createEnvironmentSlice from "./persistedSlices/environment";
import type { ISelectionState } from "./persistedSlices/selection";
import createSelectionSlice from "./persistedSlices/selection";
import type { IWishlistState } from "./persistedSlices/wishlist";
import createWishlistSlice from "./persistedSlices/wishlist";

export type IState = ISelectionState &
  IEnvironmentState &
  IWishlistState &
  IGiftCardState;

interface IGiftCardState {
  giftToken: string | null;
  setGiftToken: (token: string) => void;
  clearGiftToken: () => void;
}

const giftCardSlice: StateCreator<IState, [], [], IGiftCardState> = (
  set,
  _get,
) => ({
  giftToken: null,
  setGiftToken: (token) => set(() => ({ giftToken: token })),
  clearGiftToken: () => set(() => ({ giftToken: null })),
});

const usePersistedStore = create<IState>()(
  persist(
    (...a) => ({
      ...createSelectionSlice(...a),
      ...createEnvironmentSlice(...a),
      ...createWishlistSlice(...a),
      ...giftCardSlice(...a),
    }),
    {
      name: "persisted-state",
    },
  ),
);

export default usePersistedStore;
