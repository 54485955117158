import {
  MARK_ITALIC,
  MARK_LINK,
  NODE_PARAGRAPH,
  render,
} from "storyblok-rich-text-react-renderer";

import { ButtonAtom } from "@/lib/bloks/atoms/Button/Button";
import {
  ButtonAtomStoryblok,
  RichtextStoryblok,
} from "@/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "../Link";
import styles from "./rich-text.module.css";

interface RichTitleProps {
  children: RichtextStoryblok | string;
  className?: string;
}
const RichText = ({ children, className = "" }: RichTitleProps) => {
  return (
    <div className={clsx(className, styles.root, "richText")}>
      {render(children, {
        blokResolvers: {
          buttonAtom: (props) => {
            return (
              <ButtonAtom
                blok={props as unknown as ButtonAtomStoryblok}
                restProps={{} as any}
              />
            );
          },
        },
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => (
            <span className={clsx(styles.title, className)}>{children}</span>
          ),
        },
        markResolvers: {
          [MARK_ITALIC]: (children) => <i>{children}</i>,
          [MARK_LINK]: (children, attributes) =>
            attributes.href ? (
              <Link href={attributes.href}>{children}</Link>
            ) : (
              <>{children}</>
            ),
        },
      })}
    </div>
  );
};

export default RichText;
