import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import BlokWrap from "@/components/atoms/BlokWrap";
import { ContentSectionStoryblok } from "@/types/storyblok-blok-types";
import StoryblokComponent from "../../StoryblokComponent/StoryblokComponent";
import ContentSlides from "./components/ContentSlides";
import styles from "./content.module.css";

const ContentSection = async ({ blok }: { blok: ContentSectionStoryblok }) => {
  const { link, title, content, id } = blok;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-blok-background
      data-layout={"full"}
      className={`content-container ${styles.content}`}
      anchor={id}
    >
      {title && (
        <div className={`mobile ${styles.content__title}`}>
          <h2>{title}</h2>
        </div>
      )}
      <ContentSlides content={content} title={title} />
      {link && link[0] && (
        <div data-layout="page" className={styles.link}>
          <StoryblokComponent blok={link[0]} />
        </div>
      )}
    </BlokWrap>
  );
};

export default ContentSection;
