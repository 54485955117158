import Link from "next/link";

import { MediaElementStoryblok } from "@/types/storyblok-blok-types";
import Image from "next/image";
import MediaElement from "../MediaElement";
import styles from "./article-card.module.css";

export type ArticleCardType = {
  name: string;
  image?: MediaElementStoryblok | string;
  slug: string;
  lead?: string;
};
const ArticleCard = ({ name, image, slug, lead }: ArticleCardType) => {
  return (
    <Link href={`/${slug}`} className={styles.wrapper}>
      {typeof image === "string" ? (
        <Image height={400} width={400} src={image} alt={name} />
      ) : (
        <MediaElement blok={image as MediaElementStoryblok} />
      )}
      <div className={styles.title}>
        <h3 className={styles.name}>{name}</h3>
        <div className={styles.excerpt}>{lead}</div>
      </div>
    </Link>
  );
};

export default ArticleCard;
